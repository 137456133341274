import React from 'react'

export default function RoadmapHero() {
  return (
    <div>
              <div className="rs-breadcrumbs inner-bg1">
  <div className='roadmap-bg'>
  <div className="container">
    <div className="breadcrumbs-inner">
      <div className="breadcrumbs-wrap">
        <h1 className="page-title">
        Roadmap
        </h1>
       
      </div>
      <div className="bnr-inner-shape">
        <div className="multi-shapes one">
          {/* <img className="horizontal3" src="assets/images/breadcrumbs/shapes/b-sp1.png" alt="" /> */}
        </div>
        <div className="multi-shapes two">
          {/* <img className="spiner" src="assets/images/breadcrumbs/shapes/b-sp2.png" alt="" /> */}
        </div>
        <div className="multi-shapes three">
          {/* <img className="rotated-style" src="assets/images/breadcrumbs/shapes/b-sp3.png" alt="" /> */}
        </div>
        <div className="multi-shapes four">
          {/* <img className="horizontal2" src="assets/images/breadcrumbs/shapes/b-sp4.png" alt="" /> */}
        </div>
        <div className="multi-shapes five">
          {/* <img className="scale" src="assets/images/breadcrumbs/shapes/b-sp5.png" alt="" /> */}
        </div>
        <div className="multi-shapes six">
          {/* <img className="horizontal2" src="assets/images/breadcrumbs/shapes/b-sp6.png" alt="" /> */}
        </div>
        <div className="multi-shapes seven">
          {/* <img className="veritcal2" src="assets/images/breadcrumbs/shapes/b-sp7.png" alt="" /> */}
        </div>
        <div className="multi-shapes eight">
          {/* <img className="horizontal4" src="assets/images/breadcrumbs/shapes/b-sp8.png" alt="" /> */}
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
    </div>
  )
}
