import React from "react";

export default function TokemOurServices() {
  return (
    <div>
      <div className="rs-services services-style13 pt-130 pb-130 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pr-50 md-pr-15 md-mb-50 text-start">
              <div className="sec-title">
                {/* <span className="sub-text sub-text6">Our Services</span> */}
                <h2 className="title pb-25">
                  LMC Empowers Numerous Utilities on LikeME
                </h2>
                <p className="desc">
                  As a native currency, LMC tokenizes access to different
                  functionalities on our application.
                </p>
                <div className="btn-part mt-40">
                  <a
                    className="readon started hire-us"
                    target="_black"
                    href="https://likeme.gitbook.io/likeme-whitepaper/tokenized-integrations/lmc-token#utility-of-lmc"
                  >
                    <span className="btn-text">Read More</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 mb-20">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/purchase_subscription.png"
                          alt="Purchase Subscription"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a>Purchase Subscription</a>
                        </h4>
                        <p className="desc-text">
                          Unlock premium functions with subscription.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-20">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/transactions.png"
                          alt="Transactions"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a>Transactions </a>
                        </h4>
                        <p className="desc-text">
                          Send or receive LMC as value exchange.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 sm-mb-30">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/buy_redeem_gifts.png"
                          alt="Buy & Redeem Gifts"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a>Buy & Redeem Gifts</a>
                        </h4>
                        <p className="desc-text">
                          Exchange gifts with tokens in the application.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/tokenized_access.png"
                          alt="Tokenized Access"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a>Tokenized Access</a>
                        </h4>
                        <p className="desc-text">
                          Exclusive functionalities for token holders.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
