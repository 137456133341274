import React from "react";

export default function AppsFeatures() {
  return (
    <div>
      <div
        id="rs-choose"
        className="rs-choose choose-style1 pt-140 pb-140 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pr-100 md-pr-15 md-mb-50 text-start">
              <div className="apps-feature">
                <div className="sec-title">
                  {/* <span className="sub-text sub-text2">
            Apps Features
          </span> */}
                  <h2 className="title pb-25">
                    Replacing The Existing Social Platforms
                  </h2>
                  <p className="desc pb-30">
                    LikeME is aiming to replace the existing social media
                    applications as part of Web3 Evolution!
                  </p>
                  <ul className="check-lists check-style3">
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="list-text">
                        Empowering Decentralization
                      </span>
                    </li>
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="list-text">
                        Enhanced Data Security & Privacy
                      </span>
                    </li>
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="list-text">Censorship Resistance </span>
                    </li>
                  </ul>
                  <div className="btn-part mt-45">
                    <a
                      className="readon started get-green"
                      target="_blank"
                      href="https://likeme.gitbook.io/likeme-whitepaper/application/likeme-app"
                    >
                      <span className="btn-text"> Learn More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="wow veritcal3 animated"
                src="assets/images/newbg/phone-01.png"
                alt="Replacing The Existing Social Platforms"
                style={{
                  visibility: "visible",
                  animationName: "veritcal3",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
