import React from "react";
import Header from "../Coman/Header";
import ComingSoonHero from "./ComingSoonHero";
import Footer from "../Coman/Footer";

export default function ComingSoon() {
  return (
    <div>
      <div class="main-content">
        <Header />
        <ComingSoonHero/>
        <Footer/>
      </div>
    </div>
  );
}
