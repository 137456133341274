import React from "react";
import Header from "../Coman/Header";
import LearnnMain from "./LearnnMain";
import LearnnHero from "./LearnnHero";
import Footer from "../Coman/Footer";

export default function Learnnew() {
  document.title = ' LikeME - Learn';

  document.getElementsByTagName('meta')["description"].content = " Learn more about LikeMe Decentralized Social Media App";
  return (
    <div>
      <div class="main-content">
        <Header/>
        <LearnnHero/>
        <LearnnMain/>
        <Footer/>
      </div>
    </div>
  );
}
