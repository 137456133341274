import React from "react";

export default function NftMain() {
  return (
    <div>
      <div className="rs-about about-style1 pt-130 pb-130 gray-bg12 md-pt-80 md-pb-80">
        <div className="container ">
          <div className="row y-middle text-start">
            <div className="col-lg-6 pr-60 md-pr-15 md-mb-50">
              <div className="about-img">
                <img
                  className="scale2"
                  src="assets/images/newbg/get-likeme-nfts-unlock-premium-access.png"
                  alt="Images"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title">
                {/* <span className="sub-text sub-text3">features</span> */}
                <h2 className="title pb-25">
                  Get LikeME NFTs & Unlock Premium Access
                </h2>
                <p className="desc">
                  NFTs grant premium access to LikeME dApp, allowing holders to
                  unlock additional features & functionalities on the social
                  network.
                </p>
                <div className="btn-part mt-35">
                  <a className="readon started get-purple" href="#">
                    <span className="btn-text"> Explore NFTs</span>
                    {/* <i className="ri-arrow-right-line" /> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Feature Start */}
        <div className="rs-feature feature-style2 feature-modify3 pt-130 md-pt-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 md-mb-50 text-start">
                <div className="sec-title">
                  {/* <span className="sub-text sub-text4">benefits</span> */}
                  <h2 className="title pb-25">
                    The collection of 1000 unique pieces
                  </h2>
                  <p className="desc pb-30">
                    Featuring 6 Ranks within 1000 unique NFTs with multiple
                    utilities & extended functionalities.
                  </p>
                  <ul className="check-lists check-style4">
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text"> 1000 Unique NFTs</span>
                    </li>
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Utility Centric </span>
                    </li>
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Non-custodial</span>
                    </li>
                  </ul>
                  <div className="btn-part mt-35">
                    {/* <a
                      className="readon started get-purple"
                      href="#"
                    >
                      <span className="btn-text">Get Started</span>
                      <i className="ri-arrow-right-line" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-7 pr-60 md-pr-15 md-mb-50">
              <div className="multi-images circle2">
  <img className="scale" src="assets/images/feature/style5/circle-2.png" style={{left:"82%",position:"absolute"}} alt="Images" />
</div>
                <div className="about-img">
                  
                  <img
                    className="horizontal3"
                    src="assets/images/newbg/the-collection-of-1000-unique-pieces.png"
                    alt="Images"
                  />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Feature End */}
      </div>
    </div>
  );
}
