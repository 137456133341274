import React from "react";

export default function LearnnMain() {
  return (
    <div>
      <div className="rs-services services-style10 pt-130 pb-130 md-pt-80 md-pb-80">
  <div className="container custom6">
    <div className="sec-title text-center mb-50">
      <h2 className="title">
        Check why you<br /> 
        Should the apps
      </h2>
    </div>
    <div className="row justify-content-center">
      <div className="col-lg-4 col-md-6 md-mb-30">
        <div className="services-item learn-he">
          <div className="services-wrap  text-start">
            
            <div className="services-content">
              <h4 className="title"><a >LikeMe Whitepaper</a></h4>
              <p className="services-txt">Find out everything from the fundamentals to advanced topics about LikeME.</p>
            </div>
            <a class="readon download p-0 p-2 px-3 mt-3" target="_blank" href="https://likeme.gitbook.io/likeme-whitepaper"><span class="btn-text">Whitepaper </span></a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 md-mb-30">
        <div className="services-item learn-he">
          <div className="services-wrap  text-start">
            
            <div className="services-content">
              <h4 className="title"><a >LikeMe One-pager</a></h4>
              <p className="services-txt">A single page explainer for LikeME that gives a brief idea about the application.</p>
            </div>
            <a class="readon download p-0 p-2 px-3 mt-3" target="_blank" href="https://likeme.gitbook.io/one-pager"><span class="btn-text">Read </span></a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 md-mb-30">
        <div className="services-item learn-he">
          <div className="services-wrap  text-start">
            
            <div className="services-content">
              <h4 className="title"><a >Blogs</a></h4>
              <p className="services-txt">Read our blogs to learn more about LikeME applications & other updates.</p>
            </div>
            <a class="readon download p-0 p-2 px-3 mt-3" href="/coming-soon"><span class="btn-text">Blogs </span></a>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

      {/*  */}
      
    </div>
  );
}
