import React from 'react'

export default function WayUse() {
  return (
    <div>
            <div className="rs-cta bg11  align-items-center d-flex" style={{height:"357px"}}>
  <div className="container">
    <div className="sec-title text-center">
      <h2 className="title pb-22 white-color">
      Socialize & connect with your friends
      </h2>
      <p className="desc desc3">
      Start chatting, connecting & exploring social media – all in a decentralized manner.

      </p>
    </div>
  </div>
</div>

    </div>
  )
}
