import React from "react";

export default function TokenDetails() {
  return (
    <div>
      <div className="rs-feature feature-style2 feature-modify5 pt-140 pb-100 md-pt-80 md-pb-80">
        <div className="container custom6">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-50 text-start">
              <div className="sec-title">
                <h2 className="title pb-20">Token Details</h2>
                <p className="mb-1">
                  <span className="h6">Token Name :</span> LikeME Coin
                </p>
                <p className="mb-1">
                  <span className="h6">Ticker :</span> LMC
                </p>
                <p className="mb-1">
                  <span className="h6">Chain :</span> BNB Smart Chain
                </p>
                <p className="mb-1">
                  <span className="h6">Decimals :</span> 18
                </p>
                <p className="mb-1" style={{overflowX:"auto",overflowY:"hidden"}}>
                  <span className="h6">Contract Address :</span>{" "}
                  <a
                    target="_blanck"
                    href="https://bscscan.com/token/0x7E0e93CBB941C59e0e1139f4c3908e185eA19f97  "
                    style={{ color: "black", borderBottom: "1px solid black" }}
                  >
                    0x7E0e93CBB941C59e0e1139f4c3908e185eA19f97
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="main-wrap-img">
                <img
                  className="js-tilt"
                  src="assets/images/newbg/website-tokenomics.jpg"
                  alt="Tokenomics"
                  style={{
                    willChange: "transform",
                    transform: "perspective(300px) rotateX(0deg) rotateY(0deg)",
                  }}
                />
                {/* <div className="multi-images one">
            <img className="horizontal" src="assets/images/feature/style6/shape-1.png" alt="" />
          </div>
          <div className="multi-images two">
            <img className="horizontal2" src="assets/images/feature/style6/shape-2.png" alt="" />
          </div>
          <div className="multi-images three">
            <img className="horizontal3" src="assets/images/feature/style6/shape-3.png" alt="" />
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
