import React from 'react'

export default function OurGoal() {
  return (
    <div>
        <div className="rs-services services-style2 gray-bg4 ">

  {/* Features Start */}
  <div className="rs-feature feature-style2 pt-140 md-pt-80 pb-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="feature-main">
            <img src="assets/images/newbg/get_paid_to_post_content.png" alt="Get Paid to Post Content" />
            <div className="multi-img one">
              {/* <img className="horizontal3" src="assets/images/feature/style2/card1.png" alt="Images" /> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 text-start">
          <div className="sec-title ms-md-4 ms-0">
            <h2 className="title pb-25">
            Get Paid to Post Content
            </h2>
            <p className="desc">
            LikeME introduces Post-to-earn approach in the social media landscape where users are paid to post pictures, videos or other content!
            </p>
            <div className="btn-part mt-35">
              {/* <a className="readon started blue-normal get-free" href="#">
                <span className="btn-text"> Start Posting </span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="features-manage pt-140 md-pt-70">
        <div className="row y-middle">
          <div className="col-lg-6 md-mb-50 text-start">
            <div className="sec-title">
              <h2 className="title pb-25">
              Freedom of Content Publishing & Free Speech  

              </h2>
              <p className="desc pb-23">
              Enjoy the non-censored content policy, allowing users to post any kind of content they wish on LikeME! Unlike traditional social media platforms, LikeME does not restrict FREE SPEECH
              </p>
              {/* <p className="desc">
                Amet nisl purus in mollis nunc sed id. In hac habitasse platea dictumst quisque sagittis purus. Blandit cursus risus.
              </p> */}
              <div className="btn-part mt-35">
                <a className="readon started blue-normal get-free" href="/privacy-policy">
                  <span className="btn-text">Content Policy</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="feature-main text-right">
              <img src="assets/images/newbg/freedom_of_content_publishing.png" alt="Freedom of Content Publishing & Free Speech " />
              <div className="multi-img two">
                {/* <img className="horizontal3" src="assets/images/feature/style2/shape-1.png" alt="Images" /> */}
              </div>
              <div className="multi-img three">
                {/* <img className="horizontal4" src="assets/images/feature/style2/shape-2.png" alt="Images" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Features End */}
</div>

    </div>
  )
}
