import React from "react";
import Header from "../Coman/Header";
import PrivacyPolicyhero from "./PrivacyPolicyhero";
import Footer from "../Coman/Footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <div class="main-content">
        <Header/>
        <PrivacyPolicyhero/>
        <Footer/>
      </div>
    </div>
  );
}
