import React from 'react'

export default function Activeinstall() {
  return (
    <div>
        <div className="rs-counter counter-style1 counter-modify4">
  <div className="container">
    <div className="counter-inner-wrap pt-95 pb-95 md-pt-80 md-pb-80">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
          <div className="rs-counter-list">
            <div className="count-text">
              <div className="count-number">
                <span className="rs-count">$</span>
                <span className="prefix">5</span>	
              </div>
              <span className="title">Market Price</span>	
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
          <div className="rs-counter-list"> 
            <div className="count-text">
              <div className="count-number">
                <span className="rs-count">$4.9</span>
                <span className="prefix">M</span>	
              </div>
              <span className="title">Market Cap</span>	
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30">
          <div className="rs-counter-list"> 
            <div className="count-text">
              <div className="count-number">
                <span className="rs-count">100</span>
                <span className="prefix">+</span>
              </div>
              <span className="title">Token Holders 
              </span>	
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="rs-counter-list"> 
            <div className="count-text">
              <div className="count-number">
                <span className="rs-count">1000</span>
                <span className="prefix">+</span>
              </div>
              <span className="title">Total Transactions
              </span>	
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
