import React from "react";

export default function HighQuality() {
  return (
    <div>
      <div className="rs-videos video-style1 video-modify3 pt-130 md-pt-80">
        <div className="sec-title text-center mb-55 md-mb-35">
          <h2 className="title pb-25">
            Reshaping Social Media Landscape <br /> Using Tokenized Access
          </h2>
          <p className="desc">
            LikeME streamlines user experience within the decentralized social 
            (DeSo) ecosystem. <br /> As a social media platform, LikeMe integrates LMC 
            at its core  to ensure a <br /> seamless & rewarding experience for its
            users.
          </p>
        </div>
        <div className="video-item video-wrap4">
          <div className="overly-border">
            <a
              className="popup-border popup-videos"
              href="#"
            >
              <i className="fa fa-play" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
