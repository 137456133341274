import React from "react";
import Header from "../Coman/Header";
import LmcTokenHero from "./LmcTokenHero";
import LmcTokenMain from "./LmcTokenMain";
import Activeinstall from "./Activeinstall";
import HighQuality from "./HighQuality";
import TokemOurServices from "./TokemOurServices";
import TokenGet from "./TokenGet";
import Footer from "../Coman/Footer";
import TokenDetails from "./TokenDetails";

export default function LmcToken() {
  document.title = 'LikeME - LMC Token ';
  document.getElementsByTagName('meta')["description"].content = "LMC is the native currency to LikeME Social Media App ";

  return (
    <div>
      <div class="main-content">
        <Header />
        <LmcTokenHero />
        <LmcTokenMain/>
        <Activeinstall/>
        <TokenDetails/>
        <HighQuality/>
        <TokemOurServices/>
        <TokenGet/>
        <Footer/>
      </div>
    </div>
  );
}
