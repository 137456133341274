import { Routes, Route } from "react-router-dom";
import './App.css';
import Index from "./Components/Index/Index";
import LmcToken from "./Components/Lmc-Token/LmcToken";
import Roadmap from "./Components/Roadmap/Roadmap";
import Download from "./Components/Download/Download";
import Learnnew from "./Components/Learn/Learnnew";
import Blogs from "./Components/Blogs/Blogs";
import ComingSoon from "./Components/ComingSoon/ComingSoon";
import PrivacyPolicy from "./Components/Privacy-Policy/PrivacyPolicy";
import TermsandConditions from "./Components/Terms-and-Conditions/TermsandConditions";
import Nft from "./Components/NFT/Nft";

function App() {
  return (
    <div className="App">
       <Routes>
       <Route path="/" element={<Index />} />
       <Route path="/lmc-token" element={<LmcToken />} />
       <Route path="/roadmap" element={<Roadmap />} />
       <Route path="/download" element={<Download />} />
       <Route path="/learn" element={<Learnnew />} />
       <Route path="/blogs" element={<Blogs />} />
       <Route path="/coming-soon" element={<ComingSoon />} />
       <Route path="/privacy-policy" element={<PrivacyPolicy />} />
       <Route path="/terms-and-conditions" element={<TermsandConditions />} />
       <Route path="/nft" element={<Nft />} />
       {/* <Route path="https://affiliate.likemecoin.com" element={< ComingSoonL/>} /> */}

       </Routes>

    </div>
  );
}

export default App;
