import React from 'react'

export default function Uncensorable() {
  return (
    <div className=''>
        <div className="rs-banner banner-style4-1 d-flex align-items-center">
  <div className="container">
    <div className="row y-middle">
      <div className="col-lg-6 text-start">
        <div className="content-wrap ">
          <h2 className="text-white">LikeME is not Different but Smarter than Other Social Platforms!</h2>
          <div className="description">
            <p className='text-white'>
            Key functionalities of LikeME are integrated using blockchain technology, smart contracts, & cryptographic algorithms. 
            </p>
          </div>
          {/* <form className="newsletter-form">
            <input type="email" id="email" name="email" placeholder="Enter your email address" required />
            <input className="submit" type="submit" defaultValue="Start Free Trial" />
          </form> */}
        </div>
      </div>
      <div className="col-lg-6">
        <div className="bnr-images">
          <div className="multi-main">
            {/* <img src="assets/images/banner/style4/bnr-ly-bottom.png" alt="Images" /> */}
            <div className="multi-img bnr-top">
              {/* <img className="veritcal2" src="assets/images/banner/style4/bnr-ly-top.png" alt="Images" /> */}
            </div>
            <div className="multi-img bnr-bottom">
              {/* <img className="veritcal2" src="assets/images/banner/style4/bnr-ly-bottom.png" alt="Images" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
