/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const [showColor, setShowColor] = useState(0);
  const epoint = useLocation().pathname;

  const [headerShow, setHeaderShow] = useState(false);
  const toggleHeaderShow = () => {
    if (headerShow === "defult-home") {
      setHeaderShow("defult-home nav-expanded");
    } else {
      setHeaderShow("defult-home");
    }
  };
  useEffect(() => {
    document.body.className = headerShow;
  }, [headerShow]);
  return (
    <div>
      <div className="full-width-header">
        {/*Header Start*/}
        <header
          id="rs-header"
          className="rs-header header-style1 header-modify7"
        >
          {/* Menu Start */}
          <div className="heder-bg p-0">
            <div className="menu-area menu-sticky">
              <p className="text-center blink_me mb-0 py-2">
                <a href="" style={{ color: "#e31e24", fontWeight: "600" }}>
                  LikeME Airdrop - $50,000 Prize Pool | Claim Free LMC Tokens [
                  Join Now ]
                </a>
              </p>

              <div
                className="container-fluid2 bg-white"
                style={{ borderRadius: "25px 25px 0px 0px" }}
              >
                <div className="row-table">
                  <div className="col-cell header-logo">
                    <div className="logo-area">
                      <Link to="/">
                        <img
                          className="normal-logo img-fluid"
                          src="logo_dark.png"
                          alt="logo"
                        />
                        <img
                          className="sticky-logo"
                          src="logo_dark.png"
                          alt="logo"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-cell">
                    <div className="rs-menu-area">
                      <div className="main-menu">
                        <nav className="rs-menu hidden-md">
                          <ul className="nav-menu">
                            <li
                              onClick={() => setShowColor(0)}
                              className={
                                epoint === "/download"
                                  ? "current-menu-item"
                                  : ""
                              }
                            >
                              <Link to="/download">Download</Link>
                            </li>
                            <li
                              onClick={() => setShowColor(1)}
                              className={
                                epoint === "/lmc-token"
                                  ? "current-menu-item"
                                  : ""
                              }
                            >
                              <Link to="/lmc-token">$LMC Token</Link>
                            </li>
                            <li
                              onClick={() => setShowColor(2)}
                              className={
                                epoint === "/learn" ? "current-menu-item" : ""
                              }
                            >
                              <Link to="/learn">Learn</Link>
                            </li>
                            <li
                              onClick={() => setShowColor(3)}
                              className={
                                epoint === "/nft"
                                  ? "current-menu-item"
                                  : ""
                              }
                            >
                              <Link to="/nft">NFTs</Link>
                            </li>
                            {/* <li className="n">
                      <a href="#">Blog</a>
                      <ul className="sub-menu">
                        <li><a href="blog.html">Blog</a></li>
                        <li><a href="blog-details.html">Blog Details</a></li>
                      </ul>
                    </li> */}
                          </ul>{" "}
                          {/* //.nav-menu */}
                        </nav>
                      </div>{" "}
                      {/* //.main-menu */}
                    </div>
                  </div>
                  <div className="col-cell ">
                    <div className="expand-btn-inner ">
                      <ul>
                        <li className="btn-quote mx-2">
                          <Link
                            to="https://airdrop.likemecoin.com"
                            target="_blank"
                          >
                            <span className="btn-text">Airdrop </span>
                          </Link>
                        </li>
                        <li className="btn-quote mx-2">
                          <Link to="https://presale.likemecoin.com" target="_blank">
                            <span className="btn-text"> Presale</span>
                          </Link>
                        </li>
                        <li className="btn-quote mx-2">
                          <Link to="https://affiliate.likemecoin.com"  target="_blank">
                            <span className="btn-text"> Launch App</span>
                          </Link>
                        </li>
                        <li className="nav-link">
                          <a
                            id="nav-expander"
                            className="nav-expander bar"
                            onClick={() =>
                              toggleHeaderShow(headerShow === "defult-home")
                            }
                            // href="#"
                          >
                            <div className="bar">
                              <span className="dot1" />
                              <span className="dot2" />
                              <span className="dot3" />
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}
          {/* Canvas Mobile Menu start */}
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a
                id="nav-close2"
                className="nav-close"
                onClick={() =>
                  toggleHeaderShow(headerShow === "defult-home nav-expanded")
                }
              >
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <ul className="nav-menu">
              <li onClick={() => setShowColor(4)}>
                <Link
                  to="/download"
                  className={epoint === "/download" ? "color--2" : ""}
                >
                  Download
                </Link>
              </li>
              <li onClick={() => setShowColor(5)}>
                <Link
                  to="/$lmc-token"
                  className={epoint === "/$lmc-token" ? "color--2" : ""}
                >
                  $LMC Token
                </Link>
              </li>
              <li onClick={() => setShowColor(6)}>
                <Link
                  to="/learn"
                  className={epoint === "/learn" ? "color--2" : ""}
                >
                  Learn
                </Link>
              </li>
              <li onClick={() => setShowColor(7)}>
                <Link
                  to="/nft"
                  className={epoint === "/nft" ? "color--2" : ""}
                >
                  NFTs
                </Link>
              </li>
            </ul>{" "}
            {/* //.nav-menu */}
            {/* //.nav-menu */}
            {/* //.nav-menu */}
            <div className="canvas-contact">
              <ul className="d-flex flex-wrap">
                <li className="btn-quote m-2">
                  <Link to="https://airdrop.likemecoin.com" target="_blank">
                    <span className="btn-text">Airdrop </span>
                  </Link>
                </li>
                <li className="btn-quote m-2">
                  <Link to="https://presale.likemecoin.com"  target="_blank">
                    <span className="btn-text"> Presale</span>
                  </Link>
                </li>
                <li className="btn-quote m-2">
                  <Link to="https://affiliate.likemecoin.com"  target="_blank">
                    <span className="btn-text"> Launch App</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          {/* Canvas Menu end */}
        </header>
        {/*Header End*/}
      </div>
    </div>
  );
}
