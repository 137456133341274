import React from "react";

export default function DownloadHero() {
  return (
    <div>
      <div className="rs-banner banner-style1 ">
        <div className="container custom2 ">
          <div className="row d-flex  justify-content-center align-items-center pt-5">
       <div className="col-lg-10 mt-lg-5 mt-md-5 mt-sm-5 mt-0">
       <h1 className="title text-white">
            Download LikeME App On <br /> Your Device
            </h1>
            <div className="description text-white">
              <p>
              Experience the simplified decentralized social media application on your mobile phone!

              </p>
            <a class="readon started blue-normal get-free " href="#download"><span class="btn-text">Download</span></a>

            </div>
       </div>

          
          </div>
        </div>
        <div className="container custom3">
          <div className="bnr-images">
           
          </div>
        </div>
        <div className="banner-animate">
          <div className="bnr-animation one">
            <img
              className="scale"
              src="assets/images/banner/style1/shape1.png"
              alt=""
            />
          </div>
          {/* <div className="bnr-animation two">
            <img
              className="spiner"
              src="assets/images/banner/style1/shape2.png"
              alt=""
            />
          </div>
          <div className="bnr-animation three">
            <img src="assets/images/banner/style1/shape3.png" alt="" />
          </div>
          <div className="bnr-animation four">
            <img
              className="spiner"
              src="assets/images/banner/style1/shape2.png"
              alt=""
            />
          </div>
          <div className="bnr-animation five">
            <img
              className="horizontal"
              src="assets/images/banner/style1/shape5.png"
              alt=""
            />
          </div> */}
          <div className="bnr-animation six">
            {/* <img src="assets/images/banner/style1/shape4.png" alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
