import React from 'react'
import Header from '../Coman/Header'
import RoadmapHero from './RoadmapHero'
import RoadmapMain from './RoadmapMain'
import Footer from '../Coman/Footer'

export default function Roadmap() {
  document.title = ' LikeME - Roadmap';
  document.getElementsByTagName('meta')["description"].content = "Roadmap for LikeME representing development & growth approach ";
  return (
    <div>
           		<div class="main-content">
                    <Header/>
                    <RoadmapHero/>
                    <RoadmapMain/>
                    <Footer/>
                </div>
    </div>
  )
}
