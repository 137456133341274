import React from "react";
import Header from "../Coman/Header";
import BlogsHero from "./BlogsHero";
import BlogsMain from "./BlogsMain";
import Footer from "../Coman/Footer";

export default function Blogs() {
  return (
    <div>
      <div class="main-content">
        <Header/>
        <BlogsHero/>
        <BlogsMain/>
        <Footer/>
      </div>
    </div>
  );
}
