import React from "react";

export default function FeaturedPartners() {
  return (
    <div className="home-black">
      <div className="rs-services services-style3 pt-140 pb-140 md-pt-80 md-pb-80">
        <div className="container custom6">
          <div className="sec-title text-center mb-55 md-mb-35">
            <h2 className="title text-white">Our Partners</h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6 mb-30">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/dmail_logo.png"
                      alt="Images"
                    />
                
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-30">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/math_wallet_logo.png"
                      alt="Images"
                    />
                  
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-30">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/unilayer_logo.png"
                      alt="Images"
                    />
              
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-30">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/blur_logo.png"
                      alt="Images"
                    />
                
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/onto_logo.png"
                      alt="Images"
                    />
                
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/coin98_logo.png"
                      alt="Images"
                    />
               
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 sm-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/push_protocol_logo.png"
                      alt="Images"
                    />
            
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="services-item">
                <div className="services-wrap">
                    <img
                      className="dance_hover"
                      src="assets/images/newbg/space_id_logo.png"
                      alt="Images"
                    />
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
