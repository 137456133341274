import React from "react";

export default function Downloadpre() {
  return (
    <div>
      <div className="rs-banner rs-blog blog-main-home pt-130  md-pt-80  ">
        <div className="container custom2">
          <div className="content-wrap">
            <h1 className="title">Compatible on all devices!</h1>
            <div className="description">
              <p className="mb-0">
                LikeME is accessible on every device including mobile, tablet,
                laptops and iPads.
              </p>
              <div className="image-part d-flex justify-content-center ms-sm-2 ms-lg-5 ms-2">
  <img className="scale2 ms-sm-0 ms-lg-0 ms-xl-5 ms-0" src="assets/images/newbg/compatible-on-all-devices.png" alt=" Compatible on all devices!" />
</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
