import React from "react";

export default function Way() {
  return (
    <div>
      <div className="rs-services services-main-home services-modify1 pt-140 pb-140 md-pt-80 md-pb-80">
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-35">
            {/* <span className="sub-text sub-text2">Key Features</span> */}
            <h2 className="title">
            Why LikeME is the New <br /> Social Hub!
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6 xl-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-icon">
                  <img src="assets/images/newbg/utmost_secure.png" alt="Utmost Secur" />

                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a >Utmost Secure </a>
                    </h4>
                    <p className="services-txt">
                    Secured by blockchain-native security mechanisms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-icon">
                  <img src="assets/images/newbg/decentralized.png" alt="Decentralized" />

                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a >Decentralized</a>
                    </h4>
                    <p className="services-txt">
                    No central authority to gain control over the platform. 

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 sm-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-icon">
                  <img src="assets/images/newbg/censor_free.png" alt="Censor-free " />

                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a >Censor-free 
                      </a>
                    </h4>
                    <p className="services-txt">
                    Post as you please with no barriers to content policies. 

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-icon">
                  <img src="assets/images/newbg/global.png" alt="Global" />

                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a >Global</a>
                    </h4>
                    <p className="services-txt">
                    Globally accessible without any geographic  limitations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
