import React from "react";

export default function NftHero() {
  return (
    <div>
      <div className="rs-breadcrumbs inner-bg1">
        <div className="nft-bg-img">
          <div className="container">
            <div className="breadcrumbs-inner">
              <div className="breadcrumbs-wrap">
                <h1 className="page-title">LikeME NFTs </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
