import React from 'react'

export default function LmcTokenMain() {
  return (
    <div>
        <div className="rs-about about-style2 pt-140 pb-140 md-pt-80 md-pb-75">
  <div className="container">
    <div className="row y-middle">
      <div className="col-lg-6 md-mb-50 text-start">
        <div className="sec-title">
          {/* <span className="sub-text sub-text6">About Us</span> */}
          <h2 className="title pb-25">
          Native Currency to Empower LikeME’s Social Economy 

          </h2>
          <p className="desc pb-20">
          LMC is the native currency of LikeMe, serving as the primary utility token within the ecosystem. It is a cryptocurrency designed to facilitate functions, transactions, user incentives & a pillar for the overall economic model of the platform. 

          </p>
    
          <div className="btn-part mt-45">
            <a className="readon started get-blue get-orange" target='_blank' href="https://likeme.gitbook.io/likeme-whitepaper/tokenized-integrations/lmc-token">
              <span className="btn-text"> Read More </span>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        {/* text-right md-left */}
        <div className="about-image-wrap ">
          <img className="main-img js-tilt" src="assets/images/newbg/likeme_coin.png" alt=" LMC Coin" />
          {/* <div className="images-part">
            <img className="veritcal2" src="assets/images/about/style6/about2-1.jpg" alt="About" />
          </div> */}
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
