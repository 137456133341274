import React from "react";

export default function TermsandConditionsHero() {
  return (
    <>
      <div className="container text-start">
      <h6 className="mb-2 text-center py-3 pb-4"><strong>LikeME - Terms & Condition</strong></h6>

        <p>
          <span style={{ fontWeight: 400 }}>Welcome to LikeMe!</span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            These terms and conditions outline the rules and regulations for the
            use of LikeME's Website, located at{" "}
          </span>
          <a href="https://likemecoin.com">
            <span style={{ fontWeight: 400 }}>https://likemecoin.com</span>
          </a>
          <span style={{ fontWeight: 400 }}>.</span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use LikeMe if you do not agree to
            take all of the terms and conditions stated on this page.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves.&nbsp;
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            All terms refer to the offer, acceptance and consideration of
            payment necessary to undertake the process of our assistance to the
            Client in the most appropriate manner for the express purpose of
            meeting the Client's needs in respect of provision of the Company's
            stated services, in accordance with and subject to, prevailing law
            of underlying country.&nbsp;
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            Any use of the above terminology or other words in the singular,
            plural, capitalization and/or he/she or they, are taken as
            interchangeable and therefore as referring to the same.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>Cookies</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            We employ the use of cookies. By accessing LikeMe, you agreed to use
            cookies in agreement with the LikeME's Privacy Policy.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            Most interactive websites use cookies to let us retrieve the user's
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>License</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Unless otherwise stated, LikeME and/or its licensors own the
            intellectual property rights for all material on LikeMe. All
            intellectual property rights are reserved. You may access this from
            LikeMe for your own personal use subject to restrictions set in
            these terms and conditions. You must not:
          </span>
        </p>
      
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }} className="d-flex align-items-center">
            <i class="fa fa-solid fa-circle me-2" style={{fontSize:"8px"}}></i>    Republish material from LikeMe
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }} className="d-flex align-items-center">
            <i class="fa fa-solid fa-circle me-2" style={{fontSize:"8px"}}></i>   Sell, rent or sub-license material from LikeMe
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }} className="d-flex align-items-center">
            <i class="fa fa-solid fa-circle me-2" style={{fontSize:"8px"}}></i>    Reproduce, duplicate or copy material from LikeMe
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }} className="d-flex align-items-center">
            <i class="fa fa-solid fa-circle me-2" style={{fontSize:"8px"}}></i>   Redistribute content from LikeMe
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontWeight: 400 }}>
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the
            website.&nbsp;
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            LikeME does not filter, edit, publish or review Comments prior to
            their presence on the website. Comments do not reflect the views and
            opinions of LikeME,its agents and/or affiliates. Comments reflect
            the views and opinions of the person who post their views and
            opinions.&nbsp;
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            To the extent permitted by applicable laws, LikeME shall not be
            liable for the Comments or for any liability, damages or expenses
            caused and/or suffered as a result of any use of and/or posting of
            and/or appearance of the Comments on this website.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            LikeME reserves the right to monitor all Comments and to remove any
            Comments which can be considered inappropriate, offensive or causes
            breach of these Terms and Conditions.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You warrant and represent that:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontWeight: 400 }}>
            You hereby grant LikeME a non-exclusive license to use, reproduce,
            edit and authorize others to use, reproduce and edit any of your
            Comments in any and all forms, formats or media.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>Hyperlinking to our Content</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            The following organizations may link to our Website without prior
            written approval:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Government agencies;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Search engines;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>News organizations;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Website.
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontWeight: 400 }}>
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party's site.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            We may consider and approve other link requests from the following
            types of organizations:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              commonly-known consumer and/or business information sources;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>dot.com community sites;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              associations or other groups representing charities;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              online directory distributors;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>internet portals;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              accounting, law and consulting firms; and
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              educational institutions and trade associations.
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontWeight: 400 }}>
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of LikeME; and
            (d) the link is in the context of general resource information.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party's site.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to LikeME. Please include your name, your
            organization name, contact information as well as the URL of your
            site, a list of any URLs from which you intend to link to our
            Website, and a list of the URLs on our site to which you would like
            to link. Wait 2-3 weeks for a response.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            Approved organizations may hyperlink to our Website as follows:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              By use of our corporate name; or
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              By use of the uniform resource locator being linked to; or
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party's site.
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontWeight: 400 }}>
            No use of LikeME's logo or other artwork will be allowed for linking
            absent a trademark license agreement.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>iFrames</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>Content Liability</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>Reservation of Rights</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it's linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>
            Removal of links from our website
          </span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>Disclaimer</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              limit or exclude our or your liability for death or personal
              injury;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontWeight: 400 }}>
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </span>
        </p>
      </div>
    </>
  );
}
