import React from "react";

export default function DownloadMain() {
  return (
    <div id="download">
      <div className="rs-blog blog-main-home pt-130 pb-130 md-pt-80 md-pb-80">
        <div className="container custom">
          <div className="sec-title mb-55 md-mb-35 text-center">
            <h2 className="title">Choose Your OS</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="blog-inner-wrap">
                <div className="image-wrap">
                  <a tabIndex={0}>
                    <img src="assets/images/newbg/ios.png" alt="Images" />
                  </a>
                </div>
                <div className="blog-content">
                  <h3 className="blog-title m-0">
                    <a tabIndex={0}>iOS</a>
                  </h3>
                  <p>LikeME on App Store</p>
                  <div className="blog-button">
                    <a href="#" tabIndex={0}>
                      <span className="btn-txt">Coming Soon </span>
                      <i className="ri-arrow-right-line" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog-inner-wrap">
                <div className="image-wrap">
                  <a tabIndex={0}>
                    <img src="assets/images/newbg/android.png" alt="Images" />
                  </a>
                </div>
                <div className="blog-content">
                  <h3 className="blog-title m-0">
                    <a tabIndex={0}>Android</a>
                  </h3>
                  <p>LIkeME on Play Store</p>
                  <div className="blog-button">
                    <a href="#" tabIndex={0}>
                      <span className="btn-txt">Coming Soon </span>
                      <i className="ri-arrow-right-line" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog-inner-wrap">
                <div className="image-wrap">
                  <a tabIndex={0}>
                    <img src="assets/images/newbg/windows.png" alt="Images" />
                  </a>
                </div>
                <div className="blog-content">
                  <h3 className="blog-title m-0">
                    <a tabIndex={0}>Windows</a>
                  </h3>
                  <p>LIkeME on Windows</p>
                  <div className="blog-button">
                    <a href="#" tabIndex={0}>
                      <span className="btn-txt">Coming Soon </span>
                      <i className="ri-arrow-right-line" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
