import React from "react";

export default function Airdrop() {
  return (
    <div>
      <div className="rs-services services-main-home services-modify5 gray-bg21 pt-140 pb-140 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-6 mb-3 xl-mb-30">
              <div className="services-item">
                <div className="services-wrap text-start h-aridrop">
                  <div className="services-content">
                    <h4 className="title">
                      <a>LikeME Airdrop</a>
                    </h4>
                    <p className="services-txt">
                      Participate in the LikeME Airdrop to claim $50,000 worth
                      of free LMC tokens.
                    </p>
                  </div>
                  <div className="btn-part mt-2">
                    <a
                      className="readon download p-0 p-2 px-3"
                      href="https://airdrop.likemecoin.com" target="_blank"
                    >
                      <span className="btn-text">Airdrop ↗</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 mb-3 xl-mb-30 ">
              <div className="services-item">
                <div className="services-wrap text-start h-aridrop">
                  <div className="services-content">
                    <h4 className="title">
                      <a>LMC Presale</a>
                    </h4>
                    <p className="services-txt">
                      Join LikeME presale to buy & invest in LMC token at early
                      phase.
                    </p>
                  </div>
                  <div className="btn-part mt-2">
                    <a className="readon download p-0 p-2 px-3" href="https://presale.likemecoin.com">
                      <span className="btn-text">Presale ↗</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
