import React from "react";
import NftHero from "./NftHero";
import NftMain from "./NftMain";
import Header from "../Coman/Header";
import NftType from "./NftType";
import NftWe from "./NftWe";
import Footer from "../Coman/Footer";

export default function Nft() {
  return (
    <div>
      <div class="main-content">
        <Header />
        <NftHero />
        <NftMain />
        <NftType/>
        <NftWe/>
        <Footer/>
      </div>
    </div>
  );
}
