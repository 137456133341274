import React from "react";

export default function NftWe() {
  return (
    <div>
      <div className="rs-services services-style13 pt-130 pb-130 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row y-middle text-start">
            <div className="col-lg-6 pr-50 md-pr-15 md-mb-50">
              <div className="sec-title">
                {/* <span className="sub-text sub-text6">Our Services</span> */}
                <h2 className="title pb-25">
                  Unleashing Truly Decentralized Social Media Network
                </h2>
                <p className="desc">
                  While having NFTs, users can experience the exclusive access
                  to LikeME dApp & benefit with improved social media
                  experience.
                </p>
                <div className="btn-part mt-40">
                  <a className="readon started hire-us" href="#">
                    <span className="btn-text">Buy Now</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 mb-20">
                  <div className="services-item h-nft">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/additional_rewards.png"
                          alt="Images"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="#">Additional Rewards </a>
                        </h4>
                        <p className="desc-text">
                          Each NFT comes with an additional reward.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-20">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/premium_subscriptions.png"
                          alt="Images"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="#">Premium Subscriptions</a>
                        </h4>
                        <p className="desc-text">
                          Access premium subscription on LikeME dApp.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 sm-mb-30">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/community_ranks.png"
                          alt="Images"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="#">Community Ranks</a>
                        </h4>
                        <p className="desc-text">
                          Achiever higher ranks & outpass the community.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="assets/images/newbg/full_access_to_dapp.png"
                          alt="Images"
                        />
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="#">Full Access to dApp</a>
                        </h4>
                        <p className="desc-text">
                          Unlock all features & functionalities
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
