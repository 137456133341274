import React from "react";

export default function Highlights() {
  return (
    <div>
      <div
        id="rs-services"
        className="rs-services services-style7 gray-bg14 pt-140 pb-100 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-35">
            <h2 className="title pb-25">The Perfect Platform YOU NEED!</h2>
            <p className="desc">
              LikeME represents an opportunity to join the web3 evolution while
              keeping the experience of social media platforms at ease!{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 mt-4">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-img">
                    <img
                      src="assets/images/newbg/enhanced-user-experience01.png"
                      alt="Enhanced User Experience"
                    />
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a >Enhanced User Experience</a>
                    </h4>
                    <p className="desc-text">
                      LikeME comes handy with likewise experience of any other
                      social media platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-4 ">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-img">
                    <img
                      src="assets/images/newbg/User Video Long.gif"
                      alt="Get Notified"
                    />
                  </div>
                  <div className="services-content">
                    {/* <ul className="icons-list">
                <li>
                  <img src="assets/images/services/style9/icons/figma1.png" alt="Images" />
                </li>
                <li>
                  <img src="assets/images/services/style9/icons/figma2.png" alt="Images" />
                </li>
                <li>
                  <img src="assets/images/services/style9/icons/figma3.png" alt="Images" />
                </li>
              </ul> */}
                    <h4 className="title">
                      <a >Get Notified!</a>
                    </h4>
                    <p className="desc-text">
                      <br />
                      Receive smart-notifications even when you're away from the
                      app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-4">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-img">
                    {/* <img src="assets/images/services/style9/service-img3.jpg" alt="Images" /> */}
                    <video muted autoPlay loop id="myVideo" className="video2">
                      <source
                        src="assets/images/newbg/nft_subscriptions1.mp4"
                        type="video/mp4"
                         alt="NFT Subscriptions"
                        
                      />
                    </video>{" "}
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a >NFT Subscriptions</a>
                    </h4>
                    <p className="desc-text">
                      Tokenized access to premium content which can be
                      transferred freely.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-4">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-img">
                    <img
                      src="assets/images/newbg/exchange_redeem_gifts.png"
                      alt="Exchange & Redeem Gifts"
                    />
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a>Exchange & Redeem Gifts</a>
                    </h4>
                    <p className="desc-text mb-4">
                      Send or receive gifts using LMC token balance & redeem it
                      anytime.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
