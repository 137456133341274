import React from 'react'

export default function LmcTokenHero() {
  return (
    <div>
        <div className="rs-breadcrumbs inner-bg1">
 <div className='tokan-bg-img'>
 <div className="container">
    <div className="breadcrumbs-inner">
      <div className="breadcrumbs-wrap">
        <h1 className="page-title">
        $LMC Token
        </h1>
    
      </div>
 
    </div>
  </div>
 </div>
</div>

    </div>
  )
}
