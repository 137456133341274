import React from 'react'
import Header from '../Coman/Header'
import TermsandConditionsHero from './TermsandConditionsHero'
import Footer from '../Coman/Footer'

export default function TermsandConditions() {
  return (
    <div>
              <div class="main-content">
                <Header/>
                <TermsandConditionsHero/>
                <Footer/>
              </div>

    </div>
  )
}
