import React from "react";

export default function Hero() {
  return (
    <div>
      <div className="rs-banner banner-style11">
        <div className="container ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <div className="bnr-images">
                <div className="main-image">
                  <img
                    className="circle"
                    src="assets/images/banner/style11/circle.png"
                    alt="Images"
                  />
                  <div className="phone-img">
                    <img
                      className="scale2"
                      src="assets/images/newbg/hero_mobile.png"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrap text-start">
                <h1 className="title"><span>Join the</span> Web3 Social Network</h1>
                <div className="description">
                  <p>
                    Let’s explore the decentralized social (DeSo) realm with
                    LikeME!
                  </p>
                </div>

                <ul className="banner-btn d-flex align-items-center">
                  <li>
                    <a className="readon started blue-normal" href="#">
                      <span className="btn-text">Starting Soon</span>
                      <i className="ri-arrow-right-up-line" />
                    </a>
                  </li>
                  <li>
                    <div className="rs-videos mt--10">
                      <div className="animate-border intro-video">
                        <a
                          className="popup-border popup-videos ms-4"
                          target="_blank"
                          href="https://likeme.gitbook.io/likeme-whitepaper"
                        >
                          <span className="btn-text">Whitepaper </span>
                          <i className="ri-arrow-right-up-line" />
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* Counter Start */}
                {/* <div className="rs-counter counter-style1 counter-modify1 mt-45">
            <div className="row">
              <div className="col-lg-4 col-md-4 md-mb-30">
                <div className="rs-counter-list">
                  <div className="counter-right-border" /> 
                  <div className="count-text">
                    <div className="count-number">
                      <span className="rs-count">1M+</span>
                      <span className="prefix"> Users</span>	
                    </div>
                    <span className="title">Onboarding</span>	
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="rs-counter-list"> 
                  <div className="count-text">
                    <div className="count-number">
                      <span className="rs-count">10</span>
                      <span className="prefix">k+</span>	
                    </div>
                    <span className="title">Downloads</span>	
                  </div>
                </div>
              </div>
            </div>
          </div> */}
                {/* Counter End */}
              </div>
            </div>
          </div>
        </div>
        <div className="banner-animate">
          <div className="bnr-animation one">
            <img
              className="horizontal4"
              src="assets/images/banner/style11/shape-1.png"
              alt=""
            />
          </div>
          <div className="bnr-animation two">
            <img
              className="spiner"
              src="assets/images/banner/style11/shape-2.png"
              alt=""
            />
          </div>
          <div className="bnr-animation three">
            <img
              className="horizontal3"
              src="assets/images/banner/style11/shape-3.png"
              alt=""
            />
          </div>
          <div className="bnr-animation four">
            <img
              className="scale2"
              src="assets/images/banner/style11/shape-4.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
