import React from 'react'

export default function BlogsMain() {
  return (
    <div>
        <div className="rs-inner-blog gray-bg24 pt-120 pb-120 md-pt-80 md-pb-80">
  <div className="container">
    <div className="row">
      <div className="col-lg-8 md-mb-50">
        <div className="blog-item mb-50">
          <div className="blog-img">
            <a href="blog-single.html"><img src="assets/images/blog/style1/bl1.jpg" alt="" /></a>
            <div className="blog-meta">
              <ul className="btm-cate">
                <li>
                  <div className="user-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> Sasco
                  </div>
                </li>  
                <li>
                  <div className="blog-date">
                    <i className="ri-calendar-line" /> October 10, 2022 
                  </div>
                </li> 
                <li>
                  <div className="tag-line">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" /><polyline points="13 2 13 9 20 9" /></svg>
                    <a href="#">Video Production</a>
                  </div>
                </li> 
              </ul>
            </div>
          </div>
          <div className="blog-content">
            <h3 className="blog-title"><a href="blog-details.html">What’s Next for Sasco? Trusted Systems.</a></h3>
            <div className="blog-desc">   
              We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by...    
            </div>
            <div className="blog-button inner-btn">
              <a className="blog-btn" href="blog-details.html">Continue Reading
                <i className="ri-arrow-right-line" />
              </a>
            </div>
          </div>
        </div>
        <div className="blog-item mb-50">
          <div className="blog-img">
            <a href="blog-single.html"><img src="assets/images/blog/style1/bl2.jpg" alt="" /></a>
            <div className="blog-meta">
              <ul className="btm-cate">
                <li>
                  <div className="user-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> Sasco
                  </div>
                </li>  
                <li>
                  <div className="blog-date">
                    <i className="ri-calendar-line" /> October 10, 2022 
                  </div>
                </li> 
                <li>
                  <div className="tag-line">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" /><polyline points="13 2 13 9 20 9" /></svg>
                    <a href="#">Blockchain</a>
                  </div>
                </li> 
              </ul>
            </div>
          </div>
          <div className="blog-content">
            <h3 className="blog-title"><a href="blog-details.html">Examples Of The Best SaaS Business</a></h3>
            <div className="blog-desc">   
              We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by...
            </div>
            <div className="blog-button inner-btn">
              <a className="blog-btn" href="blog-details.html">Continue Reading
                <i className="ri-arrow-right-line" />
              </a>
            </div>
          </div>
        </div>
        <div className="blog-item mb-50">
          <div className="blog-img">
            <a href="blog-single.html"><img src="assets/images/blog/style1/bl3.jpg" alt="" /></a>
            <div className="blog-meta">
              <ul className="btm-cate">
                <li>
                  <div className="user-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> Saas
                  </div>
                </li>  
                <li>
                  <div className="blog-date">
                    <i className="ri-calendar-line" /> October 10, 2022 
                  </div>
                </li> 
                <li>
                  <div className="tag-line">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" /><polyline points="13 2 13 9 20 9" /></svg>
                    <a href="#">Crypto</a>
                  </div>
                </li> 
              </ul>
            </div>
          </div>
          <div className="blog-content">
            <h3 className="blog-title"><a href="blog-details.html">Looking Beyond Tokens Credentials.</a></h3>
            <div className="blog-desc">   
              We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by...    
            </div>
            <div className="blog-button inner-btn">
              <a className="blog-btn" href="blog-details.html">Continue Reading
                <i className="ri-arrow-right-line" />
              </a>
            </div>
          </div>
        </div>
        <div className="blog-item mb-50">
          <div className="blog-img">
            <a href="blog-single.html"><img src="assets/images/blog/style1/bl4.jpg" alt="" /></a>
            <div className="blog-meta">
              <ul className="btm-cate">
                <li>
                  <div className="user-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> Sasco
                  </div>
                </li>  
                <li>
                  <div className="blog-date">
                    <i className="ri-calendar-line" /> October 10, 2022 
                  </div>
                </li> 
                <li>
                  <div className="tag-line">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" /><polyline points="13 2 13 9 20 9" /></svg>
                    <a href="#">SaaS</a>
                  </div>
                </li> 
              </ul>
            </div>
          </div>
          <div className="blog-content">
            <h3 className="blog-title"><a href="blog-details.html">Web3 is Tokenisation of Everything</a></h3>
            <div className="blog-desc">   
              We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by...     
            </div>
            <div className="blog-button inner-btn">
              <a className="blog-btn" href="blog-details.html">Continue Reading
                <i className="ri-arrow-right-line" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="pagination-area">
            <div className="nav-link">
              <span className="page-number white-color">1</span>
              <a className="page-number" href="#">2</a>
              <a className="page-number border-none" href="#">Next</a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-12 pl-25 md-pl-15">
        <div className="widget-area">
          <div className="search-widget mb-50">
            <div className="search-wrap">
              <input type="search" placeholder="Searching..." name="s" className="search-input" defaultValue />
              <button type="submit" value="Search"><i className="ri-search-line" /></button>
            </div>
          </div>
          <div className="recent-posts mb-50">
            <div className="widget-title">
              <h3 className="title">Recent Posts</h3>
            </div>
            <div className="recent-post-widget no-border">
              <div className="post-img">
                <a href="blog-details.html"><img src="assets/images/blog/style1/inner/bl1.jpg" alt="" /></a>
              </div>
              <div className="post-desc">
                <span className="date-post"><i className="ri-calendar-line" />October 10, 2022</span>
                <a href="blog-details.html">What’s Next for Sasco? Trusted Systems.</a>
              </div>
            </div>
            <div className="recent-post-widget">
              <div className="post-img">
                <a href="blog-details.html"><img src="assets/images/blog/style1/inner/bl2.jpg" alt="" /></a>
              </div>
              <div className="post-desc">
                <span className="date-post"><i className="ri-calendar-line" />October 10, 2022</span>
                <a href="blog-details.html">Examples Of The Best SaaS Business</a>
              </div>
            </div>
            <div className="recent-post-widget">
              <div className="post-img">
                <a href="blog-details.html"><img src="assets/images/blog/style1/inner/bl3.jpg" alt="" /></a>
              </div>
              <div className="post-desc">
                <span className="date-post"><i className="ri-calendar-line" />October 10, 2022</span>
                <a href="blog-details.html">Looking Beyond Tokens Credentials.</a>
              </div>
            </div> 
          </div>
          <div className="categories mb-50">
            <div className="widget-title mb-15">
              <h3 className="title">Categories</h3>
            </div>
            <ul>
              <li><a href="#">Blockchain</a></li>
              <li><a href="#">Crypto</a></li>
              <li><a href="#">Design</a></li>
              <li><a href="#">SaaS</a></li>
            </ul>
          </div>
          <div className="tags-cloud">
            <div className="widget-title pb-23">
              <h3 className="title">Tags</h3>
            </div>
            <div className="tagcloud">
              <a href="#">Sasco</a>
              <a href="#">design</a>
              <a href="#">video</a>
              <a href="#">Blockchain</a>
              <a href="#">Crypto</a>
              <a href="#">SaaS</a>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</div>

    </div>
  )
}
