import React from 'react'
import Hero from './Hero'
import Header from '../Coman/Header'
import Highlights from './Highlights'
import OurGoal from './OurGoal'
import Uncensorable from './Uncensorable'
import Way from './Way'
import AppsFeatures from './AppsFeatures'
import WayUse from './WayUse'
import FeaturedPartners from './FeaturedPartners'
import Footer from '../Coman/Footer'
import Scroll from './Scroll'
import Airdrop from './Airdrop'


export default function Index() {
  document.title = 'LikeME - Decentralized Social Media App';
  return (
    <div>
      		<div class="main-content">
              <Header/>
              <Hero/>
              <Airdrop/>
              <Way/>
              <WayUse/>
              <Scroll/>
              <Highlights/>
              <OurGoal/>
              <Uncensorable/>
              <AppsFeatures/>
              <FeaturedPartners/>
              <Footer/>
            </div>

    </div>
  )
}
