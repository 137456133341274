import React from "react";
import Header from "../Coman/Header";
import DownloadHero from "./DownloadHero";
import DownloadMain from "./DownloadMain";
import Footer from "../Coman/Footer";
import Downloadpre from "./Downloadpre";

export default function Download() {
  document.title = 'LikeME - Download App';
  document.getElementsByTagName('meta')["description"].content = " Download LikeME App on iOS, Android or Windows Devices ";

  return (
    <div>
      <div class="main-content">
        <Header />
        <DownloadHero />
        <Downloadpre/>
        <DownloadMain />
        <Footer/>
      </div>
    </div>
  );
}
