import React from "react";

export default function TokenGet() {
  return (
    <div>
      <div className="rs-cta cta-style2 cta-modify5 cta-purple1 cta-about-style2 mb-5">
        <div className="container custom5">
          <div className="cta-wrap">
            <div className="sec-title text-center mb-35">
              <h2 className="title white-color pb-35">
              Buy LMC token now & join the social media evolution!

              </h2>
              <div className="cta-btn">
                <a
                  className="readon started small-cta orange-btn" 
                  target="_blank"
                  href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7E0e93CBB941C59e0e1139f4c3908e185eA19f97"
                >
                  <span className="btn-text">Buy LMC</span>
                </a>
              </div>
            </div>
            <div className="cta-animate">
              <div className="cta-shapes one">
                <img
                  className="rotated-style"
                  src="assets/images/cta/style3/shape-7.png"
                  alt="Images"
                />
              </div>
              <div className="cta-shapes two">
                <img
                  className="horizontal3"
                  src="assets/images/cta/style3/dots-1.png"
                  alt="Images"
                />
              </div>
              <div className="cta-shapes three">
                <img
                  className="horizontal3"
                  src="assets/images/cta/style3/dots-2.png"
                  alt="Images"
                />
              </div>
              <div className="cta-shapes four">
                <img
                  className="spiner"
                  src="assets/images/cta/style3/shape-1.png"
                  alt="Images"
                />
              </div>
              <div className="cta-shapes five">
                <img
                  className="horizontal3"
                  src="assets/images/cta/style3/shape-2.png"
                  alt="Images"
                />
              </div>
              <div className="cta-shapes six">
                <img
                  className="rotated-style"
                  src="assets/images/cta/style3/shape-6.png"
                  alt="Images"
                />
              </div>
              <div className="cta-shapes seven">
                <img
                  className="left-right2"
                  src="assets/images/cta/style3/shape-5.png"
                  alt="Images"
                />
              </div>
              <div className="cta-shapes eight">
                <img
                  className="scale2"
                  src="assets/images/cta/style3/circle-2.png"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
