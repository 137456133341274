import React from "react";
import Slider from "react-slick";

export default function NftType() {
  var settings = {
    dots: true,
    // infinite: true,
    centerMode: true,
    infinite: true,
    autoplay: true,
    // speed: 3000,
    // autoplaySpeed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,

        },
      },
    ],
  };
  return (
    <div>
      {/* <div
        id="rs-blog"
        className="rs-blog blog-main-home blog-modify1 pt-130 pb-130 md-pt-80 md-pb-80"
      >
        <div className="container custom">
          <div className="sec-title mb-55 md-mb-35 text-center">
            <h2 className="title">
              LikeME NFT <span className="new-col">Collections</span>{" "}
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-2">
              <div className="blog-item sick-slide slick-active">
                <div className="blog-inner-wrap">
                  <div className="image-wrap">
                    <a tabIndex={0}>
                      <img src="assets/images/newbg/emoji-100.jpg" alt="Images" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h3 className="blog-title mb-0">
                      <a tabIndex={0}>Elite </a>
                    </h3>
                    <ul className="blog-meta">
                      <li className="date py-2">
                        10 Rare &amp; Highest Ranking LikeME NFTs
                      </li>
                    </ul>
                    <div className="blog-button">
                      <a href="#">
                        <span className="btn-txt">Read More</span>
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-2">
              <div className="blog-item sick-slide slick-active">
                <div className="blog-inner-wrap">
                  <div className="image-wrap">
                    <a tabIndex={0}>
                      <img src="assets/images/newbg/stars-100.jpg" alt="Images" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h3 className="blog-title mb-0">
                      <a tabIndex={0}>Stars</a>
                    </h3>
                    <ul className="blog-meta">
                      <li className="date py-2">25 Most Unique Star NFTs</li>
                    </ul>
                    <div className="blog-button">
                      <a href="#">
                        <span className="btn-txt">Read More</span>
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-2 ">
              <div className="blog-item sick-slide slick-active">
                <div className="blog-inner-wrap">
                  <div className="image-wrap">
                    <a tabIndex={0}>
                      <img src="assets/images/newbg/influencers-100.jpg" alt="Images" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h3 className="blog-title mb-0">
                      <a tabIndex={0}>Influencers</a>
                    </h3>
                    <ul className="blog-meta">
                      <li className="date py-2">
                        50 Unique NFTs for Influencer spot
                      </li>
                    </ul>
                    <div className="blog-button">
                      <a href="#">
                        <span className="btn-txt">Read More</span>
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  mt-2 mt-lg-4">
              <div className="blog-item sick-slide slick-active">
                <div className="blog-inner-wrap">
                  <div className="image-wrap">
                    <a tabIndex={0}>
                      <img src="assets/images/newbg/super_users-100.jpg" alt="Images" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h3 className="blog-title mb-0">
                      <a tabIndex={0}>Super Users</a>
                    </h3>
                    <ul className="blog-meta">
                      <li className="date py-2">
                        100 Identical &amp; Premium User Rank NFTs
                      </li>
                    </ul>
                    <div className="blog-button">
                      <a href="#">
                        <span className="btn-txt">Read More</span>
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-2 mt-lg-4">
              <div className="blog-item sick-slide slick-active">
                <div className="blog-inner-wrap">
                  <div className="image-wrap">
                    <a tabIndex={0}>
                      <img src="assets/images/newbg/exclusives-100.jpg" alt="Images" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h3 className="blog-title mb-0">
                      <a tabIndex={0}>Exclusives</a>
                    </h3>
                    <ul className="blog-meta">
                      <li className="date py-2">
                        315 Identical &amp; Exclusives NFTs
                      </li>
                    </ul>
                    <div className="blog-button">
                      <a href="#">
                        <span className="btn-txt">Read More</span>
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  mt-2 mt-lg-4">
              <div className="blog-item sick-slide slick-active">
                <div className="blog-inner-wrap">
                  <div className="image-wrap">
                    <a tabIndex={0}>
                      <img src="assets/images/newbg/pro-100.jpg" alt="Images" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h3 className="blog-title mb-0">
                      <a tabIndex={0}>Pro</a>
                    </h3>
                    <ul className="blog-meta">
                      <li className="date py-2">
                        500 Identical NFTs for Pro Users
                      </li>
                    </ul>
                    <div className="blog-button">
                      <a href="#">
                        <span className="btn-txt">Read More</span>
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
      <div className="rs-apps-screenshots apps-style1 bg10 pt-50 pb-100 md-pt-40 md-pb-60">
        <div className="container">
          <div className="sec-title text-center mb-40 md-mb-20">
              {/* <span className="sub-text sub-text5">App screen</span> */}
              <h2 className="title white-color">
                LikeME
                <br />
                NFT Collections
              </h2>
            </div>
            <div className="slider apps-slide-1">
              <Slider {...settings}>
                <div>
                  <div className="grid-figure">
                    <div className="slide-image">
                      <img src="assets/images/newbg/elite.png" alt="Images" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid-figure">
                    <div className="slide-image">
                      <img src="assets/images/newbg/super_user.png" alt="Images" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid-figure">
                    <div className="slide-image">
                      <img src="assets/images/newbg/exclusives.png" alt="Images" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid-figure">
                    <div className="slide-image">
                      <img src="assets/images/newbg/pro.png" alt="Images" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid-figure">
                    <div className="slide-image">
                      <img src="assets/images/newbg/influencers.png" alt="Images" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="grid-figure">
                    <div className="slide-image">
                      <img src="assets/images/newbg/stars.png" alt="Images" />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="multi-images">
              <img
                className="scale2"
                src="assets/images/apps/circle.png"
                alt="Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
