import React from "react";

export default function RoadmapMain() {
  return (
    <div>
      <div>
        <div className="timeline">
          <ul>
            <li>
              <div className="content text-start">
                <h3> Development & Beta Launch</h3>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Development of core features
                </p>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Integration of blockchain and smart contracts
                </p>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Beta launch and user testing
                </p>
              </div>
              <div className="time">
                <h4 className="text-white">Phase 1</h4>
              </div>
            </li>
            <li>
              <div className="content text-start">
                <h3> Full Launch and Expansion</h3>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Official platform launch
                </p>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Expansion of features and functionalities
                </p>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Marketing and user acquisition campaigns
                </p>
              </div>
              <div className="time">
              <h4 className="text-white">Phase 2</h4>
              </div>
            </li>
            <li>
              <div className="content text-start">
                <h3> Full Launch and Expansion</h3>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Introduction of new digital assets and NFTs
                </p>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Continuous improvement based on user feedback
                </p>
                <p className="d-flex align-items-center mb-0">
                  <i
                    class="fa fa-solid fa-circle me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  Strategic partnerships and collaborations
                </p>
              </div>
              <div className="time">
              <h4 className="text-white">Phase 3</h4>
              </div>
            </li>
            {/* <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>April 2018</h4>
        </div>
      </li> */}
            {/* <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>May 2018</h4>
        </div>
      </li>
      <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>June 2018</h4>
        </div>
      </li>
      <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>July 2018</h4>
        </div>
      </li>
      <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>August 2018</h4>
        </div>
      </li>
      <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>September 2018</h4>
        </div>
      </li>
      <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>October 2018</h4>
        </div>
      </li>
      <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>November 2018</h4>
        </div>
      </li>
      <li>
        <div className="content">
          <h3>What is Lorem Ipsum?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        </div>
        <div className="time">
          <h4>December 2018</h4>
        </div>
      </li> */}
            <div style={{ clear: "both" }} />
          </ul>
        </div>
      </div>
    </div>
  );
}
