import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      
      <footer
        id="rs-footer"
        className="rs-footer footer-main-home footer-modify10"
      >
        <div className="container">
          <div className="footer-top">
            <div className="row text-start align-items-center">
              <div className="col-lg-4 md-mb-20 text-start">
                <div className="footer-logo">
                  <a href="/">
                    <img src="logo_dark.png" alt="" />
                  </a>
                </div>
                <p className="widget-desc">
                LikeME is a decentralized social media platform that aims to transform the way people interact online. 

                </p>
                <ul className="footer-social md-mb-30">
                  <li>
                    <a href="https://www.facebook.com/people/LikeMe-Coin/61557800742474" target="_blank">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/likemecoin">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://x.com/LikeMeCoin">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://t.me/likeme_en">
                      <i className="fa fa-telegram" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank"  href="https://www.youtube.com/channel/UCZJNs8cQQR46h555wMEG4FQ">
                    <i class="fa fa-youtube"></i>                    </a>
                  </li>
                  <li>
                    <a target="_blank"  href="https://www.linkedin.com/in/likemecoin/recent-activity/all/">
                    <i class="fa fa-brands fa-linkedin"></i>            </a>
                  </li>


                </ul>
              </div>
              <div className="col-lg-8 pl-110 md-pl-15">
                <div className="row">
                  <div className="col-lg-4 md-mb-10">
                    <h3 className="footer-title">Learn</h3>
                    <ul className="site-map">
                      {/* <li><a href="#">Learn</a></li> */}

                      <li>
                        <a target="_blank" href="https://likeme.gitbook.io/likeme-whitepaper">Whitepaper </a>
                      </li>
                      <li>
                        <a   target="_blank" href="https://likeme.gitbook.io/one-pager">One-pager </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://likeme.gitbook.io/likeme-whitepaper/media-kit">Media Kit </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 md-mb-10">
                    <h3 className="footer-title">Important Links</h3>
                    <ul className="site-map">
                      <li>
                        <a href="/lmc-token">$LMC Token</a>
                      </li>
                      <li>
                        <a href='/roadmap' >Roadmap</a>
                      </li>
                      <li>
                        <Link to="https://presale.likemecoin.com" target="_blank">Presale </Link>
                      </li>
                      <li>
                        <Link to="https://airdrop.likemecoin.com" target="_blank">Airdrop </Link>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="col-lg-4">
                    <h3 className="footer-title">Download App!
                    </h3>
                    <ul className="textwidget-btn">
                    {/* https://allstarmusicempire.com/wp-content/uploads/2015/08/comingsoon.png */}
                      <li>
                          <img src="assets/images/newbg/playstore-1.png" style={{cursor:"pointer"}} alt=""  />
                      </li>
                      <li>
                          <img src="assets/images/newbg/appstore.png" style={{cursor:"pointer"}} className="mt-2"  alt="" />
                      </li>


                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="bottom-border">
              <div className="row y-middle">
                <div className="col-lg-6 md-mb-10 text-lg-end text-center order-last">
                  <ul className="copy-right-menu">
                    <li>
                      <Link to="/privacy-policy" style={{fontWeight:"400"}}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions" style={{fontWeight:"400"}}>Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <div className="copyright text-lg-start text-center" >
                    <p style={{fontWeight:"500"}}>
                      © 2024 LikeME | All Rights Reserved.
                     
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <div id="scrollUp" class="blue-color3" style={{display:"block"}}>
			<i class="fa fa-angle-up"></i>
		</div> */}
    
    </div>
  );
}
